import { FormlyFieldConfig } from '@ngx-formly/core';

export function convertJsonSchemaToFormlyFields(
    schema: any,
    uiSchema: any = {}
): FormlyFieldConfig[] {
    const fields: FormlyFieldConfig[] = [];

    if (schema.type === 'object' && schema.properties) {
        Object.keys(schema.properties).forEach((key) => {
            const property = schema.properties[key];
            const uiOptions = uiSchema[key] || {};

            if (property.type === 'object') {
                fields.push({
                    key,
                    wrappers: ['panel'],
                    templateOptions: { label: property.title || key },
                    fieldGroup: convertJsonSchemaToFormlyFields(property, uiOptions),
                });
            } else if (property.type === 'array') {
                fields.push(mapArrayPropertyToField(key, property, uiOptions));
            } else {
                fields.push(mapPropertyToField(key, property, uiOptions));
            }
        });
    }

    return fields;
}

function mapPropertyToField(
    key: string,
    property: any,
    uiOptions: any
): FormlyFieldConfig {
    const fieldType = getFieldType(property, uiOptions);

    const field: FormlyFieldConfig = {
        key,
        type: fieldType,
        templateOptions: {
            label: property.title || key,
            description: property.description || '',
            placeholder: uiOptions['ui:placeholder'] || '',
            required: property.required || false,
            min: property.minimum,
            max: property.maximum,
            minLength: property.minLength,
            maxLength: property.maxLength,
            pattern: property.pattern,
            rows: uiOptions['ui:options']?.rows,
        },
        validators: {},
        validation: {},
    };

    if (fieldType === 'yesno') {
        field.templateOptions.options = [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ];
    } else if (property.enum) {
        field.templateOptions.options = property.enum.map((value: any) => ({
            value,
            label: value,
        }));
    }

    // Handle custom validators
    if (property.pattern) {
        field.validators = {
            pattern: {
                expression: (control) => new RegExp(property.pattern).test(control.value),
                message: `Invalid format`,
            },
        };
    }

    return field;
}

function mapArrayPropertyToField(
    key: string,
    property: any,
    uiOptions: any
): FormlyFieldConfig {
    // Handle array type fields
    return {
        key,
        type: 'repeat', // Custom type for arrays
        templateOptions: {
            label: property.title || key,
        },
        fieldArray: {
            fieldGroup: convertJsonSchemaToFormlyFields(
                property.items,
                uiOptions.items || {}
            ),
        },
    };
}

function getFieldType(property: any, uiOptions: any): string {
    if (uiOptions['ui:widget']) {
        if (property.type === 'boolean' && uiOptions['ui:widget'] === 'select') {
            return 'yesno';
        }
        return mapUiWidgetToFieldType(uiOptions['ui:widget']);
    }

    if (property.enum) {
        if (uiOptions['ui:widget'] === 'radio') {
            return 'radio';
        }
        return 'select';
    }

    switch (property.type) {
        case 'string':
            switch (property.format) {
                case 'date':
                case 'date-time':
                    return 'datepicker';
                case 'textarea':
                    return 'textarea';
                case 'password':
                    return 'input';
                default:
                    if (uiOptions['ui:widget'] === 'textarea') {
                        return 'textarea';
                    }
                    return 'input';
            }
        case 'number':
        case 'integer':
            return 'input';
        case 'boolean':
            return 'checkbox';
        default:
            return 'input';
    }
}

function mapUiWidgetToFieldType(uiWidget: string): string {
    switch (uiWidget) {
        case 'textarea':
            return 'textarea';
        case 'password':
            return 'input';
        case 'radio':
            return 'radio';
        case 'select':
            return 'select';
        case 'checkbox':
            return 'checkbox';
        case 'date':
        case 'alt-date':
            return 'datepicker';
        default:
            return 'input';
    }
}
