import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SupabaseClient, Session, AuthChangeEvent } from '@supabase/supabase-js';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PersonWithSettingService } from '../../common/supabase-services/person-with-setting.service';
import { SupabaseClientService } from '../../common/supabase-services/supabase-client.service';
import { UserSetting, ProviderType } from '../../common/supabase-models/user-setting';
import { PersonWithSetting } from '../../common/supabase-models/person-with-setting';
import { PersonService } from '../../common/supabase-services/person.service';
import { Person } from '../../common/supabase-models/person';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated = false;
    private supabase: SupabaseClient;
    public readonly user: Observable<PersonWithSetting | null> = of(null);
    private _rolesSubject = new BehaviorSubject<('user' | 'admin')[] | null>(null);
    public roles$ = this._rolesSubject.asObservable();

    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _personWithSettingService: PersonWithSettingService,
        private _supabaseClientService: SupabaseClientService,
        private _personService: PersonService,
    ) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this._supabaseClientService.getClient();

        // Monitor auth state change
        this.supabase.auth.onAuthStateChange(
            async (event: AuthChangeEvent, session: Session | null) => {
                console.log('Auth state change event:', event);
                // Handle PASSWORD_RECOVERY event
                if (event === 'PASSWORD_RECOVERY') {
                    console.log('Password recovery event detected');
                    // Navigate to the reset password page
                    this._router.navigate(['/reset-password']);
                }

                if (event === 'SIGNED_OUT') {
                    // User signed out
                    this._authenticated = false;
                    this._personWithSettingService.personWithSetting = undefined;
                    this._rolesSubject.next(null);
                    return;
                }

                if (session?.user) {
                    const userId = session.user.id;

                    // If already authenticated and the same user, skip further processing
                    if (
                        this._authenticated &&
                        this._personWithSettingService.personWithSetting &&
                        this._personWithSettingService.personWithSetting.user_id === userId
                    ) {
                        // User is already authenticated and data is loaded
                        return;
                    }

                    const userEmail = session.user.email;
                    const userProvider = session.user.app_metadata?.provider || 'email';
                    const userName = session.user.user_metadata?.name || session.user.user_metadata?.full_name || '';
                    // Split the name into parts
                    const nameParts = userName.trim().split(/\s+/); // Split by whitespace

                    let firstName = '';
                    let lastName = '';

                    if (nameParts.length > 1) {
                        firstName = nameParts[0]; // First word is the first name
                        lastName = nameParts.slice(1).join(' '); // Combine the rest as the last name
                    } else if (nameParts.length === 1) {
                        firstName = nameParts[0]; // If there's only one part, treat it as the first name
                        lastName = ''; // Leave last name empty
                    }


                    // Use the service method to fetch the existing user
                    this._personService
                        .getLinkedPersonByOwnerId(userId)
                        .subscribe(
                            async (existingUser) => {
                                if (!existingUser) {
                                    console.log(
                                        'No existing user found, inserting user to tables'
                                    );
                                    // User does not have a linked account, create the necessary records
                                    const userData = {
                                        id: userId,
                                        email: userEmail,
                                        firstName,
                                        lastName,
                                        provider: userProvider,
                                    };
                                    try {
                                        await this.insertUserToTables(userData);
                                        console.log('User inserted successfully');
                                    } catch (e) {
                                        console.error(
                                            'Error inserting user into tables:',
                                            e
                                        );
                                        // Handle the error, possibly by showing a notification or logging out the user
                                        return;
                                    }
                                } else {
                                    console.log('Existing user found');
                                }

                                // Now get the user profile
                                this._personWithSettingService
                                    .getPersonWithSettingsById(userId)
                                    .subscribe(
                                        (profile) => {
                                            if (profile) {
                                                this._authenticated = true;
                                                this._personWithSettingService.personWithSetting = profile;
                                                this._rolesSubject.next(profile.roles);
                                            } else {
                                                this._authenticated = false;
                                                this._personWithSettingService.personWithSetting = undefined;
                                                this._rolesSubject.next(null);
                                            }
                                        },
                                        (error) => {
                                            console.error(
                                                'Error retrieving user:',
                                                error
                                            );
                                            this._authenticated = false;
                                            this._personWithSettingService.personWithSetting = undefined;
                                            this._rolesSubject.next(null);
                                        }
                                    );
                            },
                            async (error) => {
                                // <-- Add 'async' here
                                // Handle the error when fetching the existing user
                                if (error.message.includes('No linked person found')) {
                                    console.log('No existing user found, inserting user to tables');
                                    // User does not have a linked account, create the necessary records
                                    const userData = {
                                        id: userId,
                                        email: userEmail,
                                        firstName,
                                        lastName,
                                        provider: userProvider,
                                    };
                                    try {
                                        await this.insertUserToTables(userData);
                                        console.log('User inserted successfully');
                                        // Proceed to get the user profile as above
                                        this._personWithSettingService
                                            .getPersonWithSettingsById(userId)
                                            .subscribe(
                                                (profile) => {
                                                    if (profile) {
                                                        this._authenticated = true;
                                                        this._personWithSettingService.personWithSetting = profile;
                                                        this._rolesSubject.next(
                                                            profile.roles
                                                        );
                                                    } else {
                                                        this._authenticated = false;
                                                        this._personWithSettingService.personWithSetting = undefined;
                                                        this._rolesSubject.next(null);
                                                    }
                                                },
                                                (error) => {
                                                    console.error(
                                                        'Error retrieving user:',
                                                        error
                                                    );
                                                    this._authenticated = false;
                                                    this._personWithSettingService.personWithSetting = undefined;
                                                    this._rolesSubject.next(null);
                                                }
                                            );
                                    } catch (e) {
                                        console.error(
                                            'Error inserting user into tables:',
                                            e
                                        );
                                        // Handle the error, possibly by showing a notification or logging out the user
                                        return;
                                    }
                                } else {
                                    console.error('Error fetching existing user:', error);
                                    // Handle other errors
                                    return;
                                }
                            }
                        );
                } else {
                    this._authenticated = false;
                    this._personWithSettingService.personWithSetting = undefined;
                    this._rolesSubject.next(null);
                }
            }
        );
    }

    // Function to map or validate the provider
    private getProvider(provider: string): ProviderType {
        switch (provider) {
            case 'email':
            case 'google':
            case 'facebook':
            case 'twitter':
                return provider;
            default:
                return 'email';
        }
    }

    // Function to insert user data into the "person" and "user_settings" tables in Supabase
    private async insertUserToTables(user: {
        id: string;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        provider: string;
    }) {
        // Insert into "person"
        const person: Partial<Person> = {
            first_name: user.firstName,
            last_name: user.lastName,
            owner_id: user.id,
            email: user.email || '',
            account_linked: true,
        };

        const { error: peopleError } = await this.supabase
            .from('person')
            .insert([person]);

        if (peopleError) {
            if (peopleError.code === '23505') {
                // PostgreSQL unique violation error code
                console.warn('Linked account already exists for this user.');
                // Proceed without throwing an error
            } else {
                console.error('Error inserting into person:', peopleError);
                throw new Error('Failed to insert into person');
            }
        }

        // Insert into "user_settings"
        const userSetting: Partial<UserSetting> = {
            id: user.id,
            account_provider: this.getProvider(user.provider),
            roles: ['user'],
            status: 'online',
            language: this.getBrowserLanguage(),
        };

        const { error: settingsError } = await this.supabase
            .from('user_settings')
            .insert([userSetting]);

        if (settingsError) {
            if (settingsError.code === '23505') {
                console.warn('User settings already exist for this user.');
                // Proceed without throwing an error
            } else {
                console.error('Error inserting into user_settings:', settingsError);
                throw new Error('Failed to insert into user_settings');
            }
        }
    }

    // Helper to get the browser's language
    private getBrowserLanguage(): 'en' | 'de' | 'fr' | 'it' {
        if (typeof navigator !== 'undefined') {
            const language = navigator.language.slice(0, 2).toLowerCase();
            return (['en', 'de', 'fr', 'it'].includes(language) ? language : 'en') as
                | 'en'
                | 'de'
                | 'fr'
                | 'it';
        } else {
            return 'en'; // Default if navigator is not available
        }
    }

    // Access Token
    async getAccessToken(): Promise<string> {
        const { data: session, error } = await this.supabase.auth.getSession();
        if (error) {
            throw new Error(error.message);
        }
        return session?.session?.access_token || '';
    }

    // Forgot password
    forgotPassword(email: string): Observable<void> {
        return from(this.supabase.auth.resetPasswordForEmail(email)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Reset Password
    resetPassword(newPassword: string): Observable<void> {
        return from(
            this.supabase.auth.updateUser({ password: newPassword })
        ).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Sign In
    signIn(credentials: { email: string; password: string }): Observable<any> {
        return from(
            this.supabase.auth.signInWithPassword({
                email: credentials.email,
                password: credentials.password,
            })
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return { _type: 'success' } as const;
            }),
            catchError((error) => {
                console.error('Error during sign-in:', error);

                // Map the error to a user-friendly message
                if (error.message === 'Invalid login credentials') {
                    return of({
                        _type: 'error',
                        code: 'invalid-credentials',
                        message: error.message,
                    } as const);
                }

                return of({
                    _type: 'error',
                    code: 'internal-error',
                    message: error.message || 'An unexpected error occurred.',
                } as const);
            })
        );
    }

    // Sign Up
    signUp(user: { name: string; email: string; password: string }): Observable<any> {
        const language = this.getBrowserLanguage();
        return from(
            this.supabase.auth.signUp({
                email: user.email,
                password: user.password,
                options: {
                    data: {
                        name: user.name,
                        language: language,
                    },
                },
            })
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return { _type: 'success' } as const;
            }),
            catchError((error) => {
                if (error.message === 'User already registered') {
                    return of({
                        _type: 'error',
                        code: 'email-already-registered',
                        message: error.message,
                    } as const);
                }
                return of({
                    _type: 'error',
                    code: 'internal-error',
                    message: error.message,
                } as const);
            })
        );
    }

    // Sign Out
    signOut(): Observable<any> {
        return from(this.supabase.auth.signOut()).pipe(
            map(() => {
                this._authenticated = false;
                this._personWithSettingService.personWithSetting = undefined;
                this._rolesSubject.next(null);

                return true;
            }),
            catchError((error) => {
                console.error('[debug] unable to sign out.', error);
                return of(false);
            })
        );
    }

    // Check session
    async check(): Promise<'unauthenticated' | 'authenticated'> {
        try {
            const { data: session } = await this.supabase.auth.getSession();
            return session?.session ? 'authenticated' : 'unauthenticated';
        } catch (error) {
            console.error('Error during session check:', error);
            return 'unauthenticated';
        }
    }

    // Social Login Methods
    async signInWithGoogle(): Promise<void> {
        const { error } = await this.supabase.auth.signInWithOAuth({
            provider: 'google',
        });
        if (error) {
            throw new Error(error.message);
        }
    }

    async signInWithFacebook(): Promise<void> {
        const { error } = await this.supabase.auth.signInWithOAuth({
            provider: 'facebook',
        });
        if (error) {
            throw new Error(error.message);
        }
    }

    async signInWithTwitter(): Promise<void> {
        const { error } = await this.supabase.auth.signInWithOAuth({
            provider: 'twitter',
        });
        if (error) {
            throw new Error(error.message);
        }
    }

    isAdmin(): boolean {
        const roles = this._rolesSubject.value;
        return roles ? roles.includes('admin') : false;
    }

    isSuperUser(): boolean {
        const roles = this._rolesSubject.value;
        return roles ? roles.includes('admin') && roles.includes('user') : false;
    }
}
