import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgIf } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-yesno',
    template: `
        <mat-form-field class="w-full" appearance="fill">
            <mat-label>{{ to.label }}</mat-label>
            <mat-select [formControl]="formControl" [formlyAttributes]="field">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    `,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgIf,
        FormlyModule,
    ],
})
export class YesNoTypeComponent extends FieldType {}
