<ng-container *ngIf="!activateEntityForm; else displayForm">
  <div class="flex flex-col justify-center items-center">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        [(ngModel)]="selectedEntity"
        (ngModelChange)="onSelect($event)"
        [compareWith]="compareWithFunction"
        [disabled]="disabled"
      >
        <mat-option *ngIf="allowAdhoc" [value]="0">+ Add beneficiary</mat-option>
        <mat-option
          *ngFor="let po of (persons$ | async); trackBy: trackById"
          [value]="po"
          [disabled]="selectedIds.includes(po.id)"
        >
          {{ (po.date_of_birth | date: 'dd.MM.yyyy') + ' ' + po.full_name }}
        </mat-option>
        <mat-option *ngIf="!(persons$ | async)?.length && !allowAdhoc" disabled>
          No available options
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!onChangeOnly" class="w-full flex justify-end items-center mt-4 px-4">
      <button mat-raised-button color="primary" (click)="onSave()" [disabled]="disabled">
        {{ btnLabel }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #displayForm>
  <app-object-form
    [method]="'create'"
    [inputData]="{ owner_id: ownerId }"
    (closeForm)="closePerson($event)"
  >
  </app-object-form>
</ng-template>