import { Component, EventEmitter, Input, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule,
    FormGroup,
    FormBuilder,
    Validators,
} from '@angular/forms';
import {
    DateAdapter,
    MatRippleModule,
    MatNativeDateModule,
} from '@angular/material/core';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';

import { InsurerWithLocalizationsJsonService } from 'app/common/supabase-services/insurer-with-localizations-json.service';
import { InsurerWithLocalizationsJson } from 'app/common/supabase-models/insurer-with-localizations-json';
import { SelectInsurerComponent } from 'app/common/components/select-insurer/select-insurer.component';
import { SelectPersonComponent } from 'app/common/components/select-person/select-person.component';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';
import { PersonService } from 'app/common/supabase-services/person.service';
import { ContractData } from 'app/common/supabase-models/policy-extraction';
import { Person } from 'app/common/supabase-models/person';

@Component({
    selector: 'app-contract-data-form',
    templateUrl: './contract-data-form.component.html',
    styleUrls: ['./contract-data-form.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRippleModule,
        MatSelectModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
        SelectPersonComponent,
        SelectInsurerComponent,
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: SwissLuxonDateAdapter,
        },
    ],
})
export class ContractDataFormComponent implements OnChanges {
    @Input() ownerId: string;
    @Input() contractData: ContractData;
    @Output() formSubmit: EventEmitter<ContractData> = new EventEmitter();
    selectedInsurer: InsurerWithLocalizationsJson;
    selectedObject: Person;
    contractDataForm: FormGroup;
    activeAdhoc: string = '';

    constructor(
        private fb: FormBuilder,
        private swissLuxonDateAdapter: SwissLuxonDateAdapter,
        private changeDetectorRef: ChangeDetectorRef,
        private insurerService: InsurerWithLocalizationsJsonService,
        private personService: PersonService,
    ) {
        this.createContractDataForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contractData && this.contractData) {
            const { insurer_id, holder_id } = this.contractData;
            // Fetch the insurer and holder details
            if (insurer_id) {
                this.insurerService.getById(Number(insurer_id)).subscribe(
                    (insurer) => {
                        this.selectedInsurer = insurer;
                        this.changeDetectorRef.markForCheck();
                    },
                    (error) => {
                        console.error('Failed to fetch insurer:', error);
                    }
                );
            }

            if (holder_id) {
                this.personService.getById(Number(holder_id)).subscribe(
                    (holder) => {
                        this.selectedObject = holder;
                        this.changeDetectorRef.markForCheck();
                    },
                    (error) => {
                        console.error('Failed to fetch holder:', error);
                    }
                );
            }

            this.contractDataForm.patchValue({
                contract_date: this.contractData.contract_date,
                effective_date: this.contractData.effective_date,
                expiration_date: this.contractData.expiration_date,
                payment_periodicity: this.contractData.payment_periodicity,
            });
        }
    }

    setInsurerAdhoc(active: boolean) {
        this.activeAdhoc = !active ? '' : 'insurer';
        this.changeDetectorRef.markForCheck();
    }

    handleInsurerChange(insurer: InsurerWithLocalizationsJson) {
        this.selectedInsurer = insurer;
    }

    setObjectAdhoc(active: boolean) {
        this.activeAdhoc = !active ? '' : 'object';
        this.changeDetectorRef.markForCheck();
    }

    handleObjectChange(object: Person) {
        this.selectedObject = object;
    }

    private createContractDataForm(): void {
        this.contractDataForm = this.fb.group({
            contract_date: ['', Validators.required],
            effective_date: ['', Validators.required],
            expiration_date: ['', Validators.required],
            payment_periodicity: ['', Validators.required],
        });
    }

    onSubmit(): void {
        if (this.contractDataForm.valid && this.selectedInsurer && this.selectedObject) {
            const { 
                contract_date, 
                effective_date, 
                expiration_date,
                payment_periodicity
            } = this.contractDataForm.value || {};

            const contractData: ContractData = {
                contract_date: this.swissLuxonDateAdapter.toIsoDateFormat(contract_date),
                effective_date: this.swissLuxonDateAdapter.toIsoDateFormat(effective_date),
                expiration_date: this.swissLuxonDateAdapter.toIsoDateFormat(expiration_date),
                payment_periodicity: payment_periodicity,
                holder_id: this.selectedObject.id,
                insurer_id: this.selectedInsurer.insurer_id,
            };

            this.formSubmit.emit(contractData);
        }
    }
}
