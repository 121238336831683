<div class="flex flex-col flex-auto w-full px-4" [formGroup]="form">
    <div class="text-lg font-semibold" *ngIf="!isAddProduct">
        {{ 'product.newProductFor' | transloco }}
        <span class="text-gray-600">"{{ form.value.type }}"</span> >
        <span class="text-gray-600">"{{ insurerName }}"</span>
    </div>
    <div class="text-lg font-semibold" *ngIf="isAddProduct">
        {{ 'product.newProduct' | transloco }}
    </div>

    <!-- Insurance Type and Insurer Selection -->
    <div class="flex flex-wrap pt-8" *ngIf="isAddProduct">
        <div class="w-full sm:w-1/2 pr-2">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ 'admin.docUploads.type' | transloco }}</mat-label>
                <mat-select formControlName="type">
                    <mat-option *ngFor="let item of (insuranceTypeCodes$ | async)" [value]="item.id">
                        {{ item.default_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="w-full sm:w-1/2 pl-2">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ 'admin.docUploads.insurance' | transloco }}</mat-label>
                <mat-select formControlName="insurerId">
                    <mat-option *ngFor="let insurer of (insurerItems$ | async)" [value]="insurer.id">
                        {{ insurer.default_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Product Names in Different Languages -->
    <div class="flex flex-col gap-4 pt-8 w-full">
        <div class="flex items-center">
            <div class="font-semibold w-16">Default</div>
            <mat-form-field appearance="outline" class="flex-grow no-hint">
                <input matInput formControlName="defName" placeholder="Default product name">
            </mat-form-field>
        </div>

        <div class="flex items-center">
            <div class="font-semibold w-16">EN</div>
            <mat-form-field appearance="outline" class="flex-grow no-hint">
                <input matInput formControlName="enName" placeholder="Product name in English">
            </mat-form-field>
        </div>

        <div class="flex items-center">
            <div class="font-semibold w-16">DE</div>
            <mat-form-field appearance="outline" class="flex-grow no-hint">
                <input matInput formControlName="deName" placeholder="Product name in German">
            </mat-form-field>
        </div>

        <div class="flex items-center">
            <div class="font-semibold w-16">FR</div>
            <mat-form-field appearance="outline" class="flex-grow no-hint">
                <input matInput formControlName="frName" placeholder="Product name in French">
            </mat-form-field>
        </div>

        <div class="flex items-center">
            <div class="font-semibold w-16">IT</div>
            <mat-form-field appearance="outline" class="flex-grow no-hint">
                <input matInput formControlName="itName" placeholder="Product name in Italian">
            </mat-form-field>
        </div>
    </div>

    <!-- Product Link -->
    <mat-form-field appearance="outline" class="w-full pt-8">
        <mat-label>{{ 'product.prodPageLink' | transloco }}</mat-label>
        <input matInput formControlName="link" [placeholder]="'product.linkToSite' | transloco">
    </mat-form-field>

    <!-- Product Description -->
    <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'product.addPlaceholder' | transloco }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

    <!-- Form Actions -->
    <div class="flex justify-end items-center gap-2 pt-4">
        <button type="button" class="px-6" mat-stroked-button [disabled]="processing" (click)="onCancel()">
            {{ 'insurers.cancel' | transloco }}
        </button>
        <button type="submit" class="px-8" mat-flat-button color="primary" [disabled]="form.invalid || processing" (click)="onSave()">
            {{ processing ? ('product.saving' | transloco) : ('product.save' | transloco) }}
        </button>
    </div>
</div>