import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    LOCALE_ID,
    Inject,
    Optional,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    FormBuilder,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogRef } from '@angular/material/dialog';

import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';

import { InsuranceProductTypeService } from 'app/common/supabase-services/insurance-product-type.service';
import { InsuranceProductService } from 'app/common/supabase-services/insurance-product.service';
import { InsuranceProductType } from 'app/common/supabase-models/insurance-product-type';
import { InsuranceProduct } from 'app/common/supabase-models/insurance-product';
import { InsurerService } from 'app/common/supabase-services/insurer.service';
import { Insurer } from 'app/common/supabase-models/insurer';

@Component({
    selector: 'app-add-product-form',
    templateUrl: './add-product-form.component.html',
    styleUrls: ['./add-product-form.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        TranslocoModule,
        CommonModule,
        NgSelectModule,
        MatSnackBarModule,
    ],
})
export class AddProductFormComponent implements OnInit {
    @Input() isAddProduct: boolean = true;
    @Input() insurerName: string = '';
    @Output() closeForm = new EventEmitter<any>();

    form: FormGroup;
    processing = false;
    insuranceTypeCodes$: Observable<InsuranceProductType[]>;
    insurerItems$: Observable<Insurer[]>;

    constructor(
        private _productService: InsuranceProductService,
        private _insuranceProductTypeService: InsuranceProductTypeService,
        private _insurerService: InsurerService,
        private _snackbar: MatSnackBar,
        private translocoService: TranslocoService,
        private _formBuilder: FormBuilder,
        @Optional() private dialogRef: MatDialogRef<AddProductFormComponent>,
        @Inject(LOCALE_ID) private locale: string
    ) {}

    ngOnInit(): void {
        // Load insurance product types and insurers
        this.insuranceTypeCodes$ = this._insuranceProductTypeService.getAll();
        this.insurerItems$ = this._insurerService.getAll();

        // Initialize form with correct data types and validations
        this.form = this._formBuilder.group({
            type: [null, Validators.required],
            insurerId: [null, Validators.required],
            defName: ['', Validators.required],
            enName: [''],
            deName: [''],
            frName: [''],
            itName: [''],
            description: [''],
            link: [''],
        });
    }

    async onSave(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        const newProduct = {
            insurance_product_type_id: this.form.value.type,
            brand_insurer_id: this.form.value.insurerId,
            default_name: this.form.value.defName,
            name_en: this.form.value.enName,
            name_de: this.form.value.deName,
            name_fr: this.form.value.frName,
            name_it: this.form.value.itName,
            description_md: this.form.value.description,
            link: this.form.value.link,
        } as InsuranceProduct;

        this.processing = true;

        try {
            await this._productService.create(newProduct).toPromise();
            this._snackbar.open('Product saved.', 'Success', { duration: 3000 });
            if (this.dialogRef) {
                this.dialogRef.close(newProduct);
            } else {
                this.closeForm.emit(newProduct);
            }
            this.form.reset();
        } catch (error) {
            console.error('Error creating product:', error);
            this._snackbar.open('Failed to create product', 'Error', { duration: 3000 });
        } finally {
            this.processing = false;
        }
    }

    onCancel(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        } else {
            this.closeForm.emit();
        }
    }

    translateButton(): string {
        const key = this.processing ? 'product.saving' : 'product.save';
        return this.translocoService.translate(key);
    }
}
