import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
    Optional,
    OnInit,
    Inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil, tap, Subscription, firstValueFrom, switchMap } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';

import { AddInsuranceFormComponent } from 'app/modules/admin/apps/document-uploads/add-insurance-form/add-insurance-form.component';
import { DocumentExtractionFormComponent } from 'app/common/components/document-extraction-form/document-extraction-form.component';
import { ContractDataFormComponent } from 'app/common/components/contract-data-form/contract-data-form.component';
import { LoadingOverlayComponent } from 'app/common/components/loading-overlay/loading-overlay.component';
import { SelectPersonComponent } from 'app/common/components/select-person/select-person.component';
import { SelectObjectComponent } from 'app/common/components/select-object/select-object.component';
import { PdfViewComponent } from 'app/common/components/pdf-view/pdf-view.component';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';

import { generateEmailNotificationData } from 'app/core/helpers/email-notification-helper';
import { SwissDateFormatPipe } from 'app/common/pipes/swiss-date-format';
import { EventMessagePipe } from 'app/common/pipes/event-message.pipe';
import { StripHtmlPipe } from 'app/common/pipes/strip-html.pipe';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';

import { PolicyExtractionViewService } from 'app/common/supabase-services/policy-extraction-view.service';
import { InsuranceGroupedViewService } from 'app/common/supabase-services/insurance-grouped-view.service';
import { PolicyExtractionService } from 'app/common/supabase-services/policy-extraction.service';
import { InsuranceService } from 'app/common/supabase-services/insurance.service';
import { EventLogService } from 'app/common/supabase-services/event-log.service';
import { AdminUserService } from 'app/common/services/admin-user.service';

import {
    PolicyExtraction,
    CoveredPerson,
    CoveredObject,
    ContractData,
} from 'app/common/supabase-models/policy-extraction';
import { PolicyExtractionView } from 'app/common/supabase-models/policy-extraction-view';
import { InsuranceGroupedView } from 'app/common/supabase-models/insurance-grouped-view';
import { Insurance } from 'app/common/supabase-models/insurance';
import { EventLog } from 'app/common/supabase-models/event-log';
import { LangCode } from 'app/common/supabase-models/common';
import { Person } from 'app/common/supabase-models/person';
import { Object } from 'app/common/supabase-models/object';

type ExtendedPerson = Person & {
    type: 'person';
};
type ExtendedObject = Object & {
    type: 'object';
};
type CoveredEntity = ExtendedPerson | ExtendedObject;

@Component({
    selector: 'app-policy-extraction-task',
    templateUrl: './policy-extraction-task.component.html',
    styleUrls: ['./policy-extraction-task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        TranslocoModule,
        MatDialogModule,
        MatSidenavModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressBarModule,
        // component imports
        DocumentExtractionFormComponent,
        SelectPersonComponent,
        SelectObjectComponent,
        ContractDataFormComponent,
        AddInsuranceFormComponent,
        SwissDateFormatPipe,
        EventMessagePipe,
        PdfViewComponent,
        StripHtmlPipe,
        ToDatePipe,
    ],
})
export class PolicyExtractionTaskComponent implements OnInit, OnDestroy {
    @ViewChild('matDrawer', { static: false }) matDrawer: MatDrawer;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private subscription = new Subscription();
    private languageSubscription: Subscription;
    insurancesGroupedByBeneficiary: {
        [beneficiaryId: string]: {
            insurances: InsuranceGroupedView[];
            insuranceIds: number[];
            pendingCount: number;
        };
    } = {};
    matDrawerContext: string = '';
    insurances: InsuranceGroupedView[] | any[] = [];
    events: EventLog[] = [];
    document: PolicyExtractionView = null;
    canMarkAsComplete: boolean = false;
    eventsLoading: boolean = false;
    insuranceLoading: boolean = false;
    isLoading: boolean = false;
    currentLanguage: LangCode;
    selectedInsuranceId: number | null = null;
    selectedCoveredEntityId: number | null = null;
    selectedCoveredEntityType: 'person' | 'object' | null = null;
    insuredType: 'person' | 'object' | null = null;
    coveredEntities: CoveredEntity[] = [];
    selectedObjectTypeId: number | null = null;

    constructor(
        @Optional() private dialogRef: MatDialogRef<PolicyExtractionTaskComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: PolicyExtractionView,
        private router: Router,
        private matDialog: MatDialog,
        private snackbar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private adminUserService: AdminUserService,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private swissLuxonDateAdapter: SwissLuxonDateAdapter,
        private policyExtractionViewService: PolicyExtractionViewService,
        private insuranceGroupedViewService: InsuranceGroupedViewService,
        private policyExtractionService: PolicyExtractionService,
        private insuranceService: InsuranceService,
        private eventLogService: EventLogService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toLowerCase() as LangCode;
            }
        );
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.fetchPolicyExtractionDetails(parseInt(id));
            this.fetchInsurancesByDocumentId(parseInt(id));
        }
    }

    private fetchPolicyExtractionDetails(id: number): void {
        this.isLoading = true;
        const dataSubscription = this.policyExtractionViewService
            .getPolicyExtractionById(id)
            .subscribe({
                next: (data: PolicyExtractionView) => {
                    this.document = data;
                    this.setCoveredEntities();
                    this.fetchEvents(data.policy_extraction_id, data.user_id);
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: () => {
                    this.isLoading = false;
                    console.error('Error fetching policy extraction details');
                },
            });
        this.subscription.add(dataSubscription);
    }

    private setCoveredEntities(): void {
        if (!this.document) {
            this.coveredEntities = [];
            return;
        }
    
        const persons = (this.document.covered_persons || []).map((person) => ({
            ...person,
            type: 'person',
        } as ExtendedPerson));
    
        const objects = (this.document.covered_objects || []).map((object) => ({
            ...object,
            type: 'object',
        } as ExtendedObject));
    
        this.coveredEntities = [...persons, ...objects];
    }

    private fetchInsurancesByDocumentId(extractionId: number): void {
        this.insuranceGroupedViewService
            .getInsuranceGroupedByExtractionId(extractionId)
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((insurances) => {
                    this.insurances = insurances;
                    // Check if there are any insurances to enable "Mark as Complete"
                    this.canMarkAsComplete = Boolean(insurances.length);
                    // Group the insurances by beneficiary for further processing/display
                    this.groupInsurancesByBeneficiary(this.insurances);
                    // Trigger change detection to update the view
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe();
    }

    private groupInsurancesByBeneficiary(insurances: InsuranceGroupedView[]): void {
        const grouped = insurances.reduce((accumulator, insurance) => {
            const coveredEntityId = insurance?.covered_person_id || insurance.covered_object_id;
            if (!accumulator[coveredEntityId]) {
                accumulator[coveredEntityId] = {
                    insurances: [],
                    insuranceIds: [],
                    pendingCount: 0,
                };
            }
            accumulator[coveredEntityId].insurances.push(insurance);
            accumulator[coveredEntityId].insuranceIds.push(insurance.insurance_id);
            if (insurance.captured_status === 'pending') {
                accumulator[coveredEntityId].pendingCount++;
            }

            return accumulator;
        }, {});

        // Sort each group's insurances by localized product_name
        Object.keys(grouped).forEach((key) => {
            grouped[key].insurances.sort((a, b) => {
                const nameA = this.getLocalizedField(a, 'product_name').toLowerCase();
                const nameB = this.getLocalizedField(b, 'product_name').toLowerCase();
                return nameA.localeCompare(nameB);
            });
        });

        this.insurancesGroupedByBeneficiary = grouped;
        this.changeDetectorRef.markForCheck();
    }

    private fetchEvents(recordId: number, ownerId: string): void {
        this.eventsLoading = true;
        const eventsSubscription = this.eventLogService
            .getEventsByRecordIdAndOwnerId(recordId, ownerId)
            .subscribe({
                next: (events) => {
                    // Map the events and set the current language if necessary
                    this.events = events.map((event: EventLog) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.eventsLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: (error) => {
                    console.error('Error fetching events:', error);
                    this.eventsLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(eventsSubscription);
    }

    markAsCompleted() {
        if (!this.document || !this.document.policy_extraction_id) {
            console.error('Document or policy extraction ID is missing.');
            return;
        }
    
        // Filter insurances that are still in 'pending' state
        const pendingInsuranceIds = this.insurances
            .filter((insurance) => insurance.captured_status === 'pending')
            .map((insurance) => insurance.insurance_id);
    
        const loadingOverlayData = {
            isLoading: true,
            loadingText: 'Marking Task and Insurances as Completed',
            successText: 'Task Marked as Completed',
        };
        const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });
    
        const updateTask$ = this.policyExtractionService.update(this.document.policy_extraction_id, { status: 'completed' });
    
        if (pendingInsuranceIds.length > 0) {
            const updateInsurances$ = this.insuranceService.updateCaptureStatus(pendingInsuranceIds, 'completed');
            updateTask$.pipe(
                switchMap(() => updateInsurances$)
            ).subscribe({
                next: () => {
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
    
                    // Refresh insurances if any were updated
                    this.fetchInsurancesByDocumentId(this.document.policy_extraction_id);
    
                    this.snackbar.open('Task and insurances marked as completed.', 'Close', {
                        duration: 3000,
                    });
    
                    // Navigate to tasks
                    this.navigateToTasks();
                },
                error: (error) => {
                    console.error('Error marking task or insurances as completed:', error);
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
                    this.snackbar.open(
                        'Failed to mark task or insurances as completed. Please try again.',
                        'Close',
                        { duration: 3000 }
                    );
                },
            });
        } else {
            // If there are no pending insurances, only update the task
            updateTask$.subscribe({
                next: () => {
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
    
                    this.snackbar.open('Task marked as completed.', 'Close', {
                        duration: 3000,
                    });
    
                    // Navigate to tasks
                    this.navigateToTasks();
                },
                error: (error) => {
                    console.error('Error marking task as completed:', error);
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
                    this.snackbar.open(
                        'Failed to mark task as completed. Please try again.',
                        'Close',
                        { duration: 3000 }
                    );
                },
            });
        }
    }

    delete(): void {
        if (this.document) {
            this.isLoading = true;
            this.policyExtractionService
                .delete(this.document.policy_extraction_id)
                .subscribe({
                    next: () => {
                        this.snackbar.open('Policy Extraction deleted.', 'Close', {
                            duration: 3000,
                        });
                        this.isLoading = false;
                        this.navigateToTasks();
                    },
                    error: (e) => {
                        console.error('Error deleting policy extraction:', e.message);
                        this.isLoading = false;
                    },
                });
        }
    }

    get unpublishEventIds(): number[] {
        return this.events
            .filter((event: EventLog) => !event.status || event.status === 'unpublished')
            .map((event: EventLog) => event.id as number);
    }

    updateSelectedEventsStatus(
        ids: number[],
        newStatus: 'unpublished' | 'published'
    ): void {
        this.eventsLoading = true;
        this.eventLogService.updateEventStatus(ids, newStatus).subscribe({
            next: () => {
                this.events = this.events.map(event => {
                    if (ids.includes(event.id)) {
                        return { ...event, status: newStatus };
                    }
                    return event;
                });
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
                console.error('Failed to update event status:', error);
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
        });
    }

    publishAllEvent() {
        const unpublishIds: number[] = this.unpublishEventIds;
        const newStatus: 'published' = 'published';
        this.updateSelectedEventsStatus(unpublishIds, newStatus);
    }

    updateEventStatus(event: EventLog) {
        let newStatus: 'unpublished' | 'published' = event?.status || 'unpublished';
        if (newStatus === 'unpublished') {
            newStatus = 'published';
        } else {
            newStatus = 'unpublished';
        }
        this.updateSelectedEventsStatus([event.id], newStatus);
    }

    get coveredPersonIds(): number[] {
        if (!this.document) {
            return [];
        } else {
            return (
                this.document?.covered_persons.map((person) => person.id) ||
                []
            );
        }
    }

    get coveredObjectIds(): number[] {
        if (!this.document) {
            return [];
        } else {
            return (
                this.document?.covered_objects.map((object) => object.id) ||
                []
            );
        }
    }

    async updateDocumentCoveredPersons(coveredPersonIds: number[]): Promise<void> {
        if (!this.document || !this.document.policy_extraction_id) {
            console.error('Document or policy extraction ID is missing.');
            return;
        }

        this.isLoading = true;
        try {
            // Update the `covered entities` field in the policy extraction
            await this.policyExtractionService
                .update(this.document.policy_extraction_id, {
                    covered_persons: coveredPersonIds,
                })
                .toPromise();

            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Error updating document covered entities:', error);
            this.changeDetectorRef.markForCheck();
        } finally {
            this.isLoading = false;
        }
    }

    async updateDocumentCoveredObjects(coveredObjectIds: number[]): Promise<void> {
        if (!this.document || !this.document.policy_extraction_id) {
            console.error('Document or policy extraction ID is missing.');
            return;
        }

        this.isLoading = true;
        try {
            // Update the `covered entities` field in the policy extraction
            await this.policyExtractionService
                .update(this.document.policy_extraction_id, {
                    covered_objects: coveredObjectIds,
                })
                .toPromise();

            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Error updating document covered entities:', error);
            this.changeDetectorRef.markForCheck();
        } finally {
            this.isLoading = false;
        }
    }

    async addBeneficiary(entity: Person | Object, entityType: 'person' | 'object'): Promise<void> {
        this.isLoading = true;
    
        try {
            const isPerson = entityType === 'person';
            const coveredEntityField = isPerson ? 'covered_persons' : 'covered_objects';
            const coveredEntityIds = isPerson ? this.coveredPersonIds : this.coveredObjectIds;
            const updateDocumentFn = isPerson ? this.updateDocumentCoveredPersons : this.updateDocumentCoveredObjects;
            const entityDetails = isPerson
                ? {
                    ...entity,
                    full_name: (entity as Person).full_name,
                    date_of_birth: (entity as Person).date_of_birth,
                }
                : { ...entity };
            const entityName = isPerson
                ? `${(entity as Person).full_name} ${this.swissLuxonDateAdapter.toSwissFormat(
                    (entity as Person).date_of_birth
                )}`
                : (entity as Object).name;
    
            const updatedIds = [...coveredEntityIds, entity.id];
            await updateDocumentFn.call(this, updatedIds);
    
            const updatedEntities = cloneDeep([...(this.document?.[coveredEntityField] || []), entityDetails]);
            this.document = cloneDeep({ ...this.document, [coveredEntityField]: updatedEntities }) as PolicyExtractionView;

            this.setCoveredEntities();
    
            await this.eventLogService
                .createEvent(
                    this.document.policy_extraction_id,
                    this.document.user_id,
                    'policy-extractions',
                    'beneficiary-added',
                    entityName
                )
                .toPromise();
    
            const emailNotificationData = generateEmailNotificationData(
                'new-insurance-beneficiary',
                this.document.user_email,
                entityName,
                this.document.policy_extraction_id.toString(),
                this.currentLanguage || 'en'
            );
            if (emailNotificationData) {
                this.adminUserService.sendEmailNotification(emailNotificationData).subscribe({
                    next: () => console.log('Email notification sent successfully'),
                    error: (error) => console.error('Failed to send email notification', error),
                });
            }
    
            this.closeDrawer();
        } catch (error) {
            console.error('Error adding covered entities:', error);
        } finally {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }
    
    async deleteBeneficiary(entity: CoveredEntity): Promise<void> {
        const isPerson = entity.type === 'person';
        const coveredEntityField = isPerson ? 'covered_persons' : 'covered_objects';
        const coveredEntityIds = isPerson ? this.coveredPersonIds : this.coveredObjectIds;
        const updateDocumentFn = isPerson ? this.updateDocumentCoveredPersons : this.updateDocumentCoveredObjects;
    
        const updatedIds = coveredEntityIds.filter((id) => id !== entity.id);
        const insuranceIds = this.insurancesGroupedByBeneficiary[entity.id]?.insuranceIds || [];
        const insurances = this.insurancesGroupedByBeneficiary[entity.id]?.insurances || [];
    
        this.isLoading = true;
    
        const updateTasks: Promise<any>[] = [updateDocumentFn.call(this, updatedIds)];
    
        const entityName = isPerson
            ? `${(entity as Person).full_name} ${this.swissLuxonDateAdapter.toSwissFormat(
                  (entity as Person).date_of_birth
              )}`
            : (entity as Object).name;
    
        const eventBeneficiaryDeletePromise = firstValueFrom(
            this.eventLogService.createEvent(
                this.document.policy_extraction_id,
                this.document.user_id,
                'policy-extractions',
                'beneficiary-deleted',
                entityName
            )
        );
    
        updateTasks.push(eventBeneficiaryDeletePromise);
    
        if (insuranceIds.length > 0) {
            const deleteMultiplePromise = firstValueFrom(this.insuranceService.deleteMultiple(insuranceIds));
            updateTasks.push(deleteMultiplePromise);
    
            // Create an event for each insurance deletion
            const eventCreationPromises: Promise<any>[] = insurances.map((insurance) => {
                const productName = this.getLocalizedField(insurance, 'product_name');
                return firstValueFrom(
                    this.eventLogService.createEvent(
                        insurance.insurance_id,
                        insurance.creator_id,
                        'insurances',
                        'insurance-deleted',
                        productName
                    )
                );
            });
            updateTasks.push(...eventCreationPromises);
        }
    
        try {
            await Promise.all(updateTasks);
    
            // Explicitly cast to a compatible type
            const entities: Array<{ id: number }> = cloneDeep(this.document?.[coveredEntityField] || []);
            const updatedEntities = entities.filter(({ id }) => id !== entity.id);
    
            this.document = cloneDeep({ ...this.document, [coveredEntityField]: updatedEntities }) as PolicyExtractionView;

            this.setCoveredEntities();
    
            this.closeDrawer();
        } catch (error) {
            console.error('Error deleting covered entity:', error);
        } finally {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    addBeneficiaryInsurance(entity: CoveredEntity) {
        this.selectedCoveredEntityId = entity.id;
        this.selectedCoveredEntityType = entity.type
        if (entity.type === 'object') {
            this.selectedObjectTypeId = entity.object_type_id;
        }

        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    editBeneficiaryInsurance(insurance: InsuranceGroupedView) {
        this.selectedInsuranceId = insurance.insurance_id;
        this.selectedCoveredEntityId = insurance?.covered_person_id || insurance?.covered_object_id;
        this.selectedCoveredEntityType = insurance?.covered_person_id ? 'person' : 'object';
        if (this.selectedCoveredEntityType === 'object') {
            this.selectedObjectTypeId = insurance.covered_object_type_id;
        }

        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    duplicateBeneficiaryInsurance(insurance: InsuranceGroupedView): void {
        const newInsurance: Insurance = {
            captured_status: 'pending',
            insurance_product_id: insurance.insurance_product_id,
            owner_id: insurance.owner_id,
            creator_id: insurance.creator_id,
            policy_holder_id: insurance.policy_holder_id,
            covered_person_id: insurance.covered_person_id,
            covered_object_id: insurance.covered_object_id,
            policy_extraction_id: insurance.policy_extraction_id,
            options_json: insurance.options_json,
            options_canonical: insurance.options_canonical,
            individual_contractual_overrides: insurance.individual_contractual_overrides,
            policy_pdf_url: insurance.policy_pdf_url,
            details_notes: insurance.details_notes,
            payment_periodicity: insurance.payment_periodicity,
            policy_date: insurance.policy_date,
            effective_date: insurance.effective_date,
            expiration_date: insurance.expiration_date,
            premium: insurance.premium,
        };
    
        this.isLoading = true;
        this.insuranceService.create(newInsurance).subscribe({
            next: (createdInsurance: Insurance) => {
                this.selectedInsuranceId = createdInsurance.id;
                this.selectedCoveredEntityId = createdInsurance?.covered_person_id || createdInsurance?.covered_object_id;
                this.selectedCoveredEntityType = createdInsurance?.covered_person_id ? 'person' : 'object';
    
                this.fetchInsurancesByDocumentId(insurance.policy_extraction_id);
    
                if (this.matDrawer) {
                    this.matDrawerContext = 'add-insurance';
                    this.matDrawer.open();
                } else {
                    console.warn('Drawer is not available');
                }
    
                this.isLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
                console.error('Error duplicating insurance:', error);
                this.isLoading = false;
                this.changeDetectorRef.markForCheck();
            },
        });
    }

    async createPublishUnpublishedEvents(
        insurances: InsuranceGroupedView[],
        newStatus: string
    ): Promise<void> {
        const eventLogs: Promise<any>[] = [];

        for (const insurance of insurances) {
            const languageProperty = this.getLocalizedField(insurance, 'product_name').toLowerCase();
            const productName = insurance[languageProperty] || insurance.product_default_name;
            const actionType =
                newStatus === 'completed'
                    ? 'insurance-published'
                    : 'insurance-unpublished';

            // Create event for 'insurances'
            eventLogs.push(
                firstValueFrom(
                    this.eventLogService.createEvent(
                        insurance.insurance_id,
                        insurance.owner_id,
                        'insurances',
                        actionType,
                        productName
                    )
                )
            );

            // Create event for 'policy-extractions'
            eventLogs.push(
                firstValueFrom(
                    this.eventLogService.createEvent(
                        insurance.policy_extraction_id,
                        insurance.owner_id,
                        'policy-extractions',
                        actionType,
                        productName,
                        false
                    )
                )
            );

            if (actionType === 'insurance-published') {
                // Process sending email notification to user
                const notificationData = generateEmailNotificationData(
                    'new-insurance',
                    this.document.user_email,
                    productName,
                    insurance.insurance_id.toString(),
                    this.currentLanguage || 'en'
                );
                if (notificationData) {
                    // Send the email notification
                    const sendNotificationResult =
                        this.adminUserService.sendEmailNotification(notificationData);
                    eventLogs.push(sendNotificationResult.toPromise());
                }
            }
        }

        await Promise.all(eventLogs);
    }

    async updateSelectedInsurancesStatus(
        ids: number[],
        newStatus: 'pending' | 'completed'
    ): Promise<void> {
        this.insuranceLoading = true;
        try {
            await firstValueFrom(
                this.insuranceService.updateCaptureStatus(ids, newStatus)
            );
        } catch (error) {
            console.error('Failed to update insurance status', error);
        } finally {
            this.insuranceLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    async updateInsuranceStatus(insurance: InsuranceGroupedView): Promise<void> {
        this.insuranceLoading = true;
    
        try {
            const newStatus: 'pending' | 'completed' =
                insurance?.captured_status === 'pending' ? 'completed' : 'pending';
    
            // Update the backend status
            await this.updateSelectedInsurancesStatus([insurance.insurance_id], newStatus);
    
            // Update the local state of the insurance
            insurance.captured_status = newStatus;
            this.updateLocalState(insurance); // Helper method to update local state
    
            // Create publish/unpublish events and notify the user
            await this.createPublishUnpublishedEvents([insurance], newStatus);
        } catch (error) {
            console.error('Error updating insurance status:', error);
        } finally {
            this.insuranceLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    async publishInsurancesByBeneficiary(id: string): Promise<void> {
        this.insuranceLoading = true;
    
        try {
            const ids = this.insurancesGroupedByBeneficiary[id].insuranceIds;
            const insurances = this.insurancesGroupedByBeneficiary[id].insurances;
            const newStatus = 'completed';
    
            // Update the backend status
            await this.updateSelectedInsurancesStatus(ids, newStatus);
    
            // Update the local state for all insurances in this beneficiary group
            insurances.forEach((insurance) => {
                insurance.captured_status = newStatus;
                this.updateLocalState(insurance); // Helper method to update local state
            });
    
            await this.createPublishUnpublishedEvents(insurances, newStatus);
        } catch (error) {
            console.error('Error publishing insurances by beneficiary:', error);
        } finally {
            this.insuranceLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    private updateLocalState(updatedInsurance: InsuranceGroupedView): void {
        // Update the flat insurances array
        const index = this.insurances.findIndex(
            (insurance) => insurance.insurance_id === updatedInsurance.insurance_id
        );
        if (index !== -1) {
            this.insurances[index] = { ...updatedInsurance };
        }
    
        // Update the grouped insurances by beneficiary
        const beneficiaryGroup = this.insurancesGroupedByBeneficiary[updatedInsurance?.covered_person_id || updatedInsurance?.covered_object_id];
        if (beneficiaryGroup) {
            const groupIndex = beneficiaryGroup.insurances.findIndex(
                (insurance) => insurance.insurance_id === updatedInsurance.insurance_id
            );
            if (groupIndex !== -1) {
                beneficiaryGroup.insurances[groupIndex] = { ...updatedInsurance };
            }
    
            // Update the pendingCount based on the insurance's status
            if (updatedInsurance.captured_status === 'pending') {
                beneficiaryGroup.pendingCount += 1;
            } else if (updatedInsurance.captured_status === 'completed') {
                beneficiaryGroup.pendingCount = Math.max(0, beneficiaryGroup.pendingCount - 1);
            }
        }
    }

    deleteInsurance(insurance: InsuranceGroupedView): void {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: this.translocoService.translate('admin.docUploads.deletingInsurance'),
            successText: this.translocoService.translate('admin.docUploads.insuranceDeleted'),
        };
        const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });
    
        firstValueFrom(this.insuranceService.delete(insurance.insurance_id))
            .then(() => {
                // Update the grouped insurance data
                const beneficiaryGroup = this.insurancesGroupedByBeneficiary[insurance.covered_person_id || insurance.covered_object_id];
                if (beneficiaryGroup) {
                    // Filter out the deleted insurance
                    beneficiaryGroup.insurances = beneficiaryGroup.insurances.filter(
                        (ins) => ins.insurance_id !== insurance.insurance_id
                    );
                    beneficiaryGroup.insuranceIds = beneficiaryGroup.insuranceIds.filter(
                        (id) => id !== insurance.insurance_id
                    );
    
                    // Update the pending count if the insurance was 'pending'
                    if (insurance.captured_status === 'pending') {
                        beneficiaryGroup.pendingCount--;
                    }
    
                    // Remove the group if no insurances are left
                    if (beneficiaryGroup.insurances.length === 0) {
                        delete this.insurancesGroupedByBeneficiary[insurance.covered_person_id || insurance.covered_object_id];
                    }
                }
    
                // Update the flat list of insurances
                this.insurances = this.insurances.filter(
                    (ins) => ins.insurance_id !== insurance.insurance_id
                );
    
                // Close the loading overlay
                loadingOverlayRef.componentInstance.data = {
                    ...loadingOverlayData,
                    isLoading: false,
                };
    
                this.changeDetectorRef.markForCheck();
            })
            .catch((error) => {
                console.error('Failed to delete insurance:', error);
                loadingOverlayRef.componentInstance.data = {
                    ...loadingOverlayData,
                    isLoading: false,
                };
                this.changeDetectorRef.markForCheck();
            });
    }

    async updateContractData(contractData: ContractData | null): Promise<void> {
        if (!this.document || !this.document.policy_extraction_id) {
            console.error('Document or policy extraction ID is missing.');
            return;
        }

        this.isLoading = true;
        try {
            // Update the `contract_data` field in the policy extraction
            await this.policyExtractionService
                .update(this.document.policy_extraction_id, {
                    contract_data: contractData,
                })
                .toPromise();

            // Update the local document state with the new contract data
            this.document = cloneDeep({ ...this.document, contract_data: contractData });

            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Failed to update contract data', error);
        } finally {
            this.isLoading = false;
            this.closeDrawer();
            this.changeDetectorRef.markForCheck();
        }
    }

    handleInsuranceFormClose(): void {
        this.closeDrawer();
        this.fetchInsurancesByDocumentId(this.document.policy_extraction_id);
        this.changeDetectorRef.markForCheck();
    }

    openDrawer(drawerType: string): void {
        if (this.matDrawer) {
            this.matDrawer.open();
            this.matDrawerContext = drawerType;
        } else {
            console.warn('Drawer is not available');
        }
    }

    closeDrawer(): void {
        if (this.matDrawer) {
            this.matDrawer.close();
            this.matDrawerContext = '';
            this.selectedInsuranceId = null;
            this.selectedCoveredEntityId = null;
            this.selectedCoveredEntityType = null;
            this.selectedObjectTypeId = null;
            this.insuredType = null;
        } else {
            console.warn('Drawer is not available');
        }
    }

    onDataUpdated(): void {
        if (this.document) {
            this.fetchEvents(
                this.document.policy_extraction_id,
                this.document.user_id
            );
        }
    }

    navigateToTasks(): void {
        this.router.navigate(['admin/tasks']);
    }

    get selectedInsurance(): InsuranceGroupedView | null {
        return (
            this.insurances.find(
                (ins) =>
                    ins.insurance_id === this.selectedInsuranceId &&
                    (ins?.covered_person_id || ins?.covered_object_id) === this.selectedCoveredEntityId
            ) || null
        );
    }

    getLocalizedField(insurance: InsuranceGroupedView, baseFieldName: string): string {
        const fieldName = `${baseFieldName}_${this.currentLanguage}`;
        return insurance[fieldName] ?? insurance[`${baseFieldName}_en`] ?? '';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
