import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ObjectType } from '../supabase-models/object-type';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class ObjectTypeService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Retrieve all records in the object_type table
     */
    getAll(): Observable<ObjectType[]> {
        return from(this.supabase.from('object_type').select('*')).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as ObjectType[];
                }
                throw new Error('No records found in object_type');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve a single record by ID
     * @param id ID of the object_type to retrieve
     */
    getById(id: number): Observable<ObjectType> {
        return from(
            this.supabase.from('object_type').select('*').eq('id', id).single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as ObjectType;
                }
                throw new Error('Record not found in object_type');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
