import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SupabaseClientService } from './supabase-client.service';
import { InsuranceProductType } from '../supabase-models/insurance-product-type';

@Injectable({
    providedIn: 'root',
})
export class InsuranceProductTypeService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    // Create a new insurance product type
    create(insuranceProductType: Partial<InsuranceProductType>): Observable<InsuranceProductType> {
        return from(this.supabase.from('insurance_product_type').insert([insuranceProductType]).select()).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                const data = response.data as InsuranceProductType[] | undefined;
                return data && data.length > 0 ? data[0] : insuranceProductType as InsuranceProductType;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get all insurance product types
    getAll(): Observable<InsuranceProductType[]> {
        return from(this.supabase.from('insurance_product_type').select('*')).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return (response.data || []) as InsuranceProductType[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get insurance product type by ID
    getById(id: number): Observable<InsuranceProductType> {
        return from(this.supabase.from('insurance_product_type').select('*').eq('id', id).single()).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return response.data as InsuranceProductType;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update insurance product type by ID
    update(id: number, insuranceProductType: Partial<InsuranceProductType>): Observable<InsuranceProductType> {
        return from(this.supabase.from('insurance_product_type').update(insuranceProductType).eq('id', id)).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                const data = response.data as InsuranceProductType[] | undefined;
                return data && data.length > 0 ? data[0] : { ...insuranceProductType, id } as InsuranceProductType;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete insurance product type by ID
    delete(id: number): Observable<void> {
        return from(this.supabase.from('insurance_product_type').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
