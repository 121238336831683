import { Injectable } from '@angular/core';
import {
    SupabaseClient,
    RealtimeChannel,
    RealtimePostgresChangesPayload,
} from '@supabase/supabase-js';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SupabaseClientService } from './supabase-client.service';
import { InsuranceGroupedView } from '../supabase-models/insurance-grouped-view';
import { Insurance } from '../supabase-models/insurance';

@Injectable({
    providedIn: 'root',
})
export class InsuranceGroupedViewService {
    private supabase: SupabaseClient;
    private channel: RealtimeChannel;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    getAllInsuranceGroupedView(): Observable<InsuranceGroupedView[]> {
        return from(
            this.supabase
                .from('insurance_grouped_view')
                .select('*')
        ).pipe(
            map((response) => {
                if (response.error) {
                    console.error('Supabase error:', response.error);
                    throw response.error;
                }
                return (response.data || []) as InsuranceGroupedView[];
            }),
            catchError((error) => {
                console.error('Error in getAllInsuranceGroupedView:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    getInsuranceGroupedByOwnerId(ownerId: string): Observable<InsuranceGroupedView[]> {
        return from(
            this.supabase
                .from('insurance_grouped_view')
                .select('*')
                .eq('owner_id', ownerId)
                .eq('captured_status', 'completed')
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceGroupedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    getInsuranceGroupedByExtractionId(extractionId: number): Observable<InsuranceGroupedView[]> {
        return from(
            this.supabase
                .from('insurance_grouped_view')
                .select('*')
                .eq('policy_extraction_id', extractionId)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceGroupedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    subscribeToInsurancesByOwnerId(
        ownerId: string
    ): Observable<RealtimePostgresChangesPayload<any>> {
        return new Observable((observer) => {
            this.channel = this.supabase
                .channel(`public:insurance:owner_id=eq.${ownerId}`)
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: 'insurance',
                        filter: `owner_id=eq.${ownerId}`,
                    },
                    async (payload) => {
                        const insurance = payload.new as Insurance;
                        if (insurance.captured_status === 'completed') {
                            try {
                                // Query the specific record from `insurance_grouped_view`
                                const { data, error } = await this.supabase
                                    .from('insurance_grouped_view')
                                    .select('*')
                                    .eq('insurance_id', insurance.id)
                                    .single();
    
                                if (error) {
                                    console.error(
                                        'Error querying insurance_grouped_view:',
                                        error
                                    );
                                    return;
                                }
    
                                if (data) {
                                    // Emit the specific record to the observer
                                    observer.next({ ...payload, new: data });
                                }
                            } catch (err) {
                                console.error('Error in subscription handler:', err);
                                observer.error(err);
                            }
                        }
                    }
                )
                .subscribe();
                
            return () => {
                this.supabase.removeChannel(this.channel);
            };
        });
    }
}
