<div class="flex flex-col justify-center items-center">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Select Product Type</mat-label>
    <mat-select
      [(ngModel)]="selectedProductTypeId"
      (ngModelChange)="onProductTypeSelect($event)"
    >
      <mat-option
        *ngFor="let productType of (productTypes$ | async)"
        [value]="productType.id"
      >
        {{ getProductTypeName(productType) }}
      </mat-option>
      <mat-option *ngIf="!(productTypes$ | async)?.length" disabled>
        No available options
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>