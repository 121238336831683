import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslocoService, TranslocoModule } from '@ngneat/transloco';

import { LangCode } from 'app/common/supabase-models/common';
import { EventLog } from 'app/common/supabase-models/event-log';
import { EventLogService } from 'app/common/supabase-services/event-log.service';
import { PersonWithSetting } from 'app/common/supabase-models/person-with-setting';
import { PersonWithSettingService } from 'app/common/supabase-services/person-with-setting.service';
import { EventMessagePipe } from 'app/common/pipes/event-message.pipe';

interface ExtendedEventLog extends EventLog {
    expanded: boolean;
    routerLink?: string[];
    queryParams?: object;
}

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        RouterModule,
        TranslocoModule,
        EventMessagePipe,
    ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: any;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private languageSubscription: Subscription;

    unreadCount: number = 0;
    events: ExtendedEventLog[] = [];
    user: PersonWithSetting | null = null;
    currentLanguage: LangCode;

    constructor(
        private _overlay: Overlay,
        private _changeDetectorRef: ChangeDetectorRef,
        private _viewContainerRef: ViewContainerRef,
        private translocoService: TranslocoService,
        private _eventLogService: EventLogService,
        private _personWithSettingService: PersonWithSettingService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.slice(0, 2).toLowerCase() as LangCode;
            }
        );
    }

    ngOnInit(): void {
        this._personWithSettingService.personWithSetting$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: PersonWithSetting) => {
                if (user && this.user?.user_id !== user?.user_id) {
                    this.user = user;
                    this._subscribeToNotifications(this.user.user_id);
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        if (this._overlayRef) {
            this._overlayRef.dispose();
        }

        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    openPanel(): void {
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        if (!this._overlayRef) {
            this._createOverlay();
        }

        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    toggleRead(event: ExtendedEventLog): void {
        const read = !event.read;
        this._eventLogService
            .markEventsReadOrUnread([event.id as number], read)
            .subscribe({
                next: () => {
                    event.read = read;
                    this._calculateUnreadCount();
                    this._changeDetectorRef.markForCheck();
                },
                error: (error) =>
                    console.error(`Failed to toggle read status: ${error.message}`),
            });
    }

    markAllAsRead(): void {
        const ids = this.events.map((event) => event.id as number);
        this._eventLogService.markEventsReadOrUnread(ids, true).subscribe({
            next: () => {
                this.events.forEach((event) => (event.read = true));
                this.unreadCount = 0;
                this._changeDetectorRef.markForCheck();
            },
            error: (error) =>
                console.error(`Failed to mark all as read: ${error.message}`),
        });
    }

    delete(event: ExtendedEventLog): void {
        this._eventLogService
            .removeEventsFromNotifications([event.id as number])
            .subscribe({
                next: () => {
                    this.events = this.events.filter((e) => e.id !== event.id);
                    this._calculateUnreadCount();
                    this._changeDetectorRef.markForCheck();
                },
                error: (error) =>
                    console.error(`Failed to delete notification: ${error.message}`),
            });
    }

    expandNotification(index: number): void {
        this.events[index] = {
            ...this.events[index],
            expanded: !this.events[index].expanded,
        };
        this._changeDetectorRef.markForCheck();
    }

    trackByFn(index: number, item: ExtendedEventLog): any {
        return item.id || index;
    }

    private _subscribeToNotifications(userId: string): void {
        this._eventLogService
            .listenForEventNotifications(userId)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((events) => {
                this.events = events.map((event) => {
                    const { routerLink, queryParams } = this._eventLogService.mapActionToRoute(event);
                    return {
                        ...event,
                        language: this.currentLanguage,
                        expanded: false,
                        routerLink,
                        queryParams,
                    };
                });
                this._calculateUnreadCount();
                this._changeDetectorRef.markForCheck();
            });
    }

    private _createOverlay(): void {
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ]),
        });

        this._overlayRef.backdropClick().subscribe(() => {
            this.closePanel();
        });
    }

    private _calculateUnreadCount(): void {
        this.unreadCount = this.events.filter((event) => !event.read).length;
    }
}
