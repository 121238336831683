import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'formly-wrapper-panel',
    template: `
        <mat-card class="mb-4">
            <mat-card-header>
                <mat-card-title class="font-semibold mb-3">{{ to.label }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ng-container #fieldComponent></ng-container>
            </mat-card-content>
        </mat-card>
    `,
    standalone: true,
    imports: [MatCardModule],
})
export class PanelWrapperComponent extends FieldWrapper {}
