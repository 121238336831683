import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';

import { PanelWrapperComponent } from './panel-wrapper.component';
import { DatepickerTypeComponent } from './datepicker.type';
import { RepeatTypeComponent } from './repeat.type';
import { YesNoTypeComponent } from './yesno.type';

import { convertJsonSchemaToFormlyFields } from './json-schema-to-formly';

@Component({
    selector: 'app-dynamic-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        // Angular Material Modules
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatButtonModule,
        // Custom Field Components
        DatepickerTypeComponent,
        PanelWrapperComponent,
        RepeatTypeComponent,
        YesNoTypeComponent,
    ],
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
    @Input() formGroup: FormGroup;
    @Input() optionsJsonSchema: any;
    @Input() optionsUiSchema: any;
    @Input() initialValues: any;

    fields: FormlyFieldConfig[] = [];

    ngOnInit() {
        this.initializeForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.options ||
            changes.optionsJsonSchema ||
            changes.optionsUiSchema ||
            changes.initialValues
        ) {
            this.initializeForm();
        }
    }

    private initializeForm() {
        if (this.optionsJsonSchema) {
            // Convert schema to Formly fields
            this.fields = convertJsonSchemaToFormlyFields(
                this.optionsJsonSchema,
                this.optionsUiSchema
            );

            // Set initial values
            if (this.initialValues) {
                this.formGroup.patchValue(this.initialValues);
            } else {
                console.log('No initial values provided to DynamicFormComponent.');
            }
        } else {
            console.error('No optionsJsonSchema provided to DynamicFormComponent.');
        }
    }
}
