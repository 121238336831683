import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';

import { UsersnapComponent } from './mock-api/apps/usersnap/usersnap.component';
import { PersonWithSettingService } from 'app/common/supabase-services/person-with-setting.service';
import { PersonWithSetting } from 'app/common/supabase-models/person-with-setting';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, UsersnapComponent, TranslocoModule],
})
export class AppComponent implements OnInit {
    activeLangDisplay: string;
    defaultLanguage: string;
    savedLanguage: string;

    constructor(
        private _translocoService: TranslocoService,
        private _personWithSettingService: PersonWithSettingService
    ) {}

    ngOnInit(): void {
        const browserLanguage = navigator.language || 'en-US';
        this.defaultLanguage = browserLanguage.split('-')[0];

        // Subscribe to personWithSetting changes
        this._personWithSettingService.personWithSetting$.subscribe((person: PersonWithSetting) => {
            if (person) {
                // Use the saved language from person_with_setting
                this.savedLanguage = person.language || this.defaultLanguage;
                this._translocoService.setActiveLang(this.savedLanguage);
            } else {
                // If no person is available (e.g. not logged in), fallback to default
                this._translocoService.setActiveLang(this.defaultLanguage);
            }
        });
    }
}