import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-datepicker',
    template: `
        <mat-form-field appearance="fill">
            <mat-label>{{ to.label }}</mat-label>
            <input
                matInput
                [matDatepicker]="picker"
                [formControl]="formControl"
                [formlyAttributes]="field"
                [placeholder]="to.placeholder" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint *ngIf="to.description">{{ to.description }}</mat-hint>
            <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
        </mat-form-field>
    `,
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        FormlyModule,
    ],
})
export class DatepickerTypeComponent extends FieldType<any> {}
