<ng-container *ngIf="!activateProductForm; else addProductForm">
  <div class="flex flex-col justify-center items-center">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ 'Select Insurance Product' }}</mat-label>
      <mat-select
        [formControl]="selectedProductControl"
        (selectionChange)="onProductSelected($event.value)"
      >
        <mat-option
          *ngFor="let product of (displayProducts$ | async); trackBy: trackById"
          [value]="product.insurance_product_id"
        >
          {{ product.productName }}
        </mat-option>
        <mat-option *ngIf="!(displayProducts$ | async)?.length && !allowAdhoc" disabled>
          No available options
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>

<ng-template #addProductForm>
  <app-add-product-form
    [isAddProduct]="true"
    (closeForm)="onAddProductClosed($event)"
  ></app-add-product-form>
</ng-template>