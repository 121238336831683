import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Person } from '../supabase-models/person';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class PersonService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Create a new record in the person table
     * @param entity Person data to insert
     */
    create(entity: Person): Observable<Person> {
        return from(
            this.supabase.from('person').insert([entity]).select()
        ).pipe(
            map((response) => {
                if (response.data && (response.data as Person[]).length > 0) {
                    return (response.data as Person[])[0] as Person;
                }
                throw new Error('Failed to create record in person');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve a record by ID
     * @param id ID of the record to retrieve
     */
    getById(id: number): Observable<Person> {
        return from(
            this.supabase.from('person').select('*').eq('id', id).single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as Person;
                }
                throw new Error('Record not found in person');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    getByIds(ids: number[]): Observable<Person[]> {
        return from(
            this.supabase.from('person').select('*').in('id', ids)
        ).pipe(map((response) => response.data as Person[]));
    }

    /**
     * Update a record by ID
     * @param id ID of the record to update
     * @param entity Updated data
     */
    update(id: number, entity: Partial<Person>): Observable<Person> {
        return from(
            this.supabase.from('person').update(entity).eq('id', id).select()
        ).pipe(
            map((response) => {
                // Check if the update operation returned an error
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of Person
                const data = response.data as Person[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the update was successful
                return entity as Person;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Delete a record by ID
     * @param id ID of the record to delete
     */
    delete(id: number): Observable<void> {
        return from(this.supabase.from('person').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve all records in the person table
     */
    getAll(): Observable<Person[]> {
        return from(this.supabase.from('person').select('*')).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as Person[];
                }
                throw new Error('No records found in person');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve a linked person by owner ID
     * @param ownerId Owner ID to filter records
     */
    getLinkedPersonByOwnerId(ownerId: string): Observable<Person> {
        return from(
            this.supabase
                .from('person')
                .select('*')
                .eq('owner_id', ownerId)
                .eq('account_linked', true)
                .single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as Person;
                }
                throw new Error('No linked person found for the specified owner ID');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve all records by owner ID
     * @param ownerId Owner ID to filter records
     */
    getAllByOwnerId(ownerId: string): Observable<Person[]> {
        return from(
            this.supabase.from('person').select('*').eq('owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as Person[];
                }
                throw new Error(`No records found for owner ID: ${ownerId}`);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
