<ng-container *ngIf="!activateInsurerForm; else displayForm;">
  <div class="flex flex-col justify-center items-center">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        [(ngModel)]="selectedInsurer"
        (ngModelChange)="onSelect($event)"
        [compareWith]="compareWithFunction"
      >
        <mat-option *ngIf="allowAdhoc" [value]="0">+ Add insurer</mat-option>
        <mat-option
          *ngFor="let insurer of (insurers$ | async); trackBy: trackById"
          [value]="insurer"
          [disabled]="selectedInsurer?.insurer_id === insurer.insurer_id"
        >
          {{ getLocalizedInsurerName(insurer) }}
        </mat-option>
        <mat-option *ngIf="!(insurers$ | async)?.length && !allowAdhoc" disabled>
          No available options
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      *ngIf="!onChangeOnly"
      class="w-full flex justify-end items-center mt-4 px-4"
    >
      <button mat-raised-button color="primary" (click)="onSave()">
        {{ btnLabel }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #displayForm>
  <insurer-details
    [insurer]="null"
    (closeDetails)="closeInsurer($event)"
  ></insurer-details>
</ng-template>