import {
    Component,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
    Output,
    OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InsuranceProductType } from '../../supabase-models/insurance-product-type';
import { InsuranceProductTypeService } from '../../supabase-services/insurance-product-type.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'select-product-type',
    templateUrl: './select-product-type.component.html',
    styleUrls: ['./select-product-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslocoModule,
        FormsModule,
    ],
})
export class SelectProductTypeComponent implements OnInit, OnDestroy {
    @Input() productTypeId: number | null = null;
    @Output() selectedProductType = new EventEmitter<InsuranceProductType | null>();

    productTypes$ = new BehaviorSubject<InsuranceProductType[]>([]);
    selectedProductTypeId: number | null = null;
    private ngUnsubscribe = new Subject<void>();
    selectedLanguage: string;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private insuranceProductTypeService: InsuranceProductTypeService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.translocoService.langChanges$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lang) => {
                this.selectedLanguage = lang;
                this.fetchProductTypes();
                this._changeDetectorRef.markForCheck();
            });

        // Initial fetch
        this.fetchProductTypes();
    }

    fetchProductTypes() {
        this.insuranceProductTypeService
            .getAll()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (productTypes) => {
                    productTypes.sort((a, b) => {
                        const nameA = this.getProductTypeName(a).toLowerCase();
                        const nameB = this.getProductTypeName(b).toLowerCase();
                        return nameA.localeCompare(nameB);
                    });

                    this.productTypes$.next(productTypes);

                    // Set initial selection
                    this.selectedProductTypeId = this.productTypeId ?? -1;
                    this._changeDetectorRef.markForCheck();
                },
                error: (err) => {
                    console.error('Error fetching product types:', err);
                },
            });
    }

    getProductTypeName(productType: InsuranceProductType): string {
        switch (this.selectedLanguage) {
            case 'de':
                return productType.name_de || productType.default_name;
            case 'en':
                return productType.name_en || productType.default_name;
            case 'fr':
                return productType.name_fr || productType.default_name;
            case 'it':
                return productType.name_it || productType.default_name;
            default:
                return productType.default_name;
        }
    }

    onProductTypeSelect(productTypeId: number | null): void {
        if (productTypeId === -1 || productTypeId == null) {
            this.selectedProductType.emit(null);
        } else {
            const productType = this.productTypes$.value.find(
                (pt) => pt.id === productTypeId
            );
            this.selectedProductType.emit(productType || null);
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }
}
