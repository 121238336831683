import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-repeat',
    template: `
        <div *ngFor="let field of field.fieldGroup; let i = index">
            <formly-group [field]="field"></formly-group>
            <div class="mb-3">
                <button mat-raised-button color="warn" type="button" (click)="remove(i)">
                    Remove
                </button>
            </div>
        </div>
        <div>
            <button mat-raised-button color="primary" type="button" (click)="add()">
                Add
            </button>
        </div>
    `,
    standalone: true,
    imports: [CommonModule, MatButtonModule, FormlyModule],
})
export class RepeatTypeComponent extends FieldArrayType<any> {}
