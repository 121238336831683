import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ObjectFormComponent } from 'app/modules/admin/apps/people-objects/object-form/object-form.component';
import { PersonService } from 'app/common/supabase-services/person.service';
import { Person } from 'app/common/supabase-models/person';

@Component({
    selector: 'app-select-person',
    templateUrl: './select-person.component.html',
    styleUrls: ['./select-person.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatButtonModule,
        TranslocoModule,
        MatFormFieldModule,
        ObjectFormComponent,
    ],
})
export class SelectPersonComponent implements OnInit, OnChanges {
    @Input() entityId: number | null = null;
    @Input() ownerId: string;
    @Input() selectedIds: number[] = [];
    @Input() onChangeOnly: boolean = false;
    @Input() label: string = 'Select an option';
    @Input() btnLabel: string = 'Save';
    @Input() disabled: boolean = false;
    @Input() allowAdhoc: boolean = false;
    @Output() onChanges = new EventEmitter<Person>();
    @Output() adhocToggle = new EventEmitter<boolean>();
    @Output() onClickSave = new EventEmitter<Person>();

    persons$: Observable<Person[]>;
    selectedEntity: Person | null = null;
    activateEntityForm: boolean = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private personService: PersonService
    ) {}

    ngOnInit() {
        this.loadPersons();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ownerId || changes.entityId) {
            this.loadPersons();
        }
    }

    loadPersons() {
        if (this.ownerId) {
            this.persons$ = this.personService
                .getAllByOwnerId(this.ownerId)
                .pipe(
                    tap((objects) => {
                        objects.sort((a, b) => {
                            const nameA = (a.full_name || '').toLowerCase();
                            const nameB = (b.full_name || '').toLowerCase();
                            return nameA.localeCompare(nameB);
                        });
    
                        if (this.entityId) {
                            this.selectedEntity = objects.find((po) => po.id === this.entityId) || null;
                        }
                        this.changeDetectorRef.markForCheck();
                    })
                );
        } else {
            this.persons$ = of([]);
        }
    }

    closePerson(person?: Person) {
        if (person) {
            this.selectedEntity = person;
            this.onValueChange();
        }
        this.onAdhocToggle(false);
        this.loadPersons();
        this.changeDetectorRef.detectChanges();
    }

    onSelect(selectedValue: Person | number): void {
        if (!this.disabled) {
            if (typeof selectedValue !== 'number') {
                this.selectedEntity = selectedValue;
                this.onValueChange();
            } else {
                if (selectedValue === 0 && this.allowAdhoc) {
                    this.onAdhocToggle(true);
                }
            }
        }
    }

    onAdhocToggle(toggle: boolean) {
        this.activateEntityForm = toggle;
        this.adhocToggle.emit(this.activateEntityForm);
    }

    onValueChange() {
        if (this.onChangeOnly) {
            this.onChanges.emit(this.selectedEntity);
        }
    }

    onSave() {
        this.onClickSave.emit(this.selectedEntity);
    }

    compareWithFunction(item1: Person, item2: Person) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }

    trackById(index: number, item: Person) {
        return item.id;
    }
}