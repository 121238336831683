import {
    Component,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InsuranceProductExpandedView } from '../../supabase-models/insurance-product-expanded-view';
import { InsuranceProductExpandedViewService } from '../../supabase-services/insurance-product-expanded-view.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';

import { AddProductFormComponent } from '../add-product-form/add-product-form.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

interface InsuranceProductDisplayItem {
    insurance_product_id: number;
    productName: string;
}

@Component({
    selector: 'select-product',
    templateUrl: './select-product.component.html',
    styleUrls: ['./select-product.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslocoModule,
        AddProductFormComponent,
        MatFormFieldModule,
        MatSelectModule,
    ],
})
export class SelectProductComponent implements OnInit, OnChanges, OnDestroy {
    @Input() insurerId: number;
    @Input() insuranceTypeId: number;
    @Input() productId: number | null = null;
    @Input() allowAdhoc: boolean = false;
    @Output() selectedProduct = new EventEmitter<InsuranceProductExpandedView | null>();

    products$ = new BehaviorSubject<InsuranceProductExpandedView[]>([]);
    displayProducts$ = new BehaviorSubject<InsuranceProductDisplayItem[]>([]);
    selectedProductControl = new FormControl();
    selectedLanguage: string;
    activateProductForm: boolean = false;
    private ngUnsubscribe = new Subject<void>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private insuranceProductExpandedViewService: InsuranceProductExpandedViewService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        // Subscribe to language changes
        this.translocoService.langChanges$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lang) => {
                this.selectedLanguage = lang;
                this.fetchProducts();
                this._changeDetectorRef.detectChanges();
            });

        // Initial product fetch
        this.fetchProducts();

        // Subscribe to selected product control changes
        this.selectedProductControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((productId) => {
                this.onProductSelected(productId);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.insuranceTypeId || changes.insurerId) {
            this.fetchProducts();
        }

        if (
            changes.productId &&
            changes.productId.currentValue !== changes.productId.previousValue
        ) {
            this.selectedProductControl.setValue(changes.productId.currentValue);
            this._changeDetectorRef.markForCheck();
        }
    }

    onProductSelected(productId: number) {
        if (productId == null) {
            // No selection
            this.selectedProduct.emit(null);
        } else if (productId === -1) {
            // Handle 'Add New' selection
            if (this.allowAdhoc) {
                this.activateProductForm = true;
                this.selectedProductControl.reset(); // Reset selection
                this.selectedProduct.emit(null);
            }
        } else {
            const product = this.products$.value.find(
                (p) => p.insurance_product_id === productId
            );
            this.selectedProduct.emit(product || null);
        }
    }

    fetchProducts() {
        if (!this.insurerId || !this.insuranceTypeId) {
            this.displayProducts$.next([]);
            return;
        }
    
        this.insuranceProductExpandedViewService
            .getExpandedInsuranceProducts(this.insurerId, this.insuranceTypeId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (products) => {
                    products.sort((a, b) => {
                        const nameA = this.getProductName(a).toLowerCase();
                        const nameB = this.getProductName(b).toLowerCase();
                        return nameA.localeCompare(nameB);
                    });
    
                    this.products$.next(products);
    
                    // Create display products with productName
                    const displayProducts: InsuranceProductDisplayItem[] = products.map(
                        (product) => ({
                            insurance_product_id: product.insurance_product_id,
                            productName: this.getProductName(product),
                        })
                    );
    
                    // Add 'Add New' item if allowed
                    if (this.allowAdhoc) {
                        const addNewItem: InsuranceProductDisplayItem = {
                            insurance_product_id: -1,
                            productName: this.translocoService.translate('product.addItem'),
                        };
                        displayProducts.unshift(addNewItem);
                    }
    
                    this.displayProducts$.next(displayProducts);
    
                    // Set the selected product ID only if it's defined
                    if (this.productId !== null && this.productId !== undefined) {
                        this.selectedProductControl.setValue(this.productId);
                    }
    
                    this._changeDetectorRef.markForCheck();
                },
                error: (err) => {
                    console.error('Error fetching products:', err);
                },
            });
    }

    getProductName(product: InsuranceProductExpandedView): string {
        switch (this.selectedLanguage) {
            case 'de':
                return product.product_name_de || product.product_default_name;
            case 'en':
                return product.product_name_en || product.product_default_name;
            case 'fr':
                return product.product_name_fr || product.product_default_name;
            case 'it':
                return product.product_name_it || product.product_default_name;
            default:
                return product.product_default_name;
        }
    }

    trackById(index: number, item: InsuranceProductDisplayItem) {
        return item.insurance_product_id;
    }

    onAddProductClosed(newProduct: any): void {
        this.activateProductForm = false;
        if (newProduct) {
            // Refresh product list and select the new product
            this.fetchProducts();
            this.selectedProductControl.setValue(newProduct.id);
            this.selectedProduct.emit(newProduct);
        }
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions when the component is destroyed
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}